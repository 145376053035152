.pageBackground{
  width: 100vw;
  height: 100vh;
  background-color: #eeeeee;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.fullPage{
  display: flex;
  width: 100%;
  max-height: calc(100vh - 60px);
  flex-direction: column;
  padding: 30px;
  overflow: hidden;
  max-width: 1000px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ed9f3c;
  border-radius: 5px;
}

::-webkit-scrollbar-corner{
  background: transparent;
}