.userBackButton{
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: #ed9f3c;
  color: white;
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
  width: 120px;
}

.userBackButton:hover{
  background-color: #d18d35;
}

.userInfoBox{
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 15px;
  border: 1px solid #aaaaaa;
  box-shadow: -1px 2px 4px 0px #bbbbbb;
  padding: 5px 15px;
  color: black;
  overflow-y: scroll;
}

.userEditButton{
  border: none;
  outline: none;
  color: #ed9f3c;
  background-color: transparent;
  cursor: pointer;
  transform: scale(1.8);
  margin-right: 5px;
}

.userEditButton:hover{
  color: #d18d35
}