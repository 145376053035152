.userFormInputFieldGroups{
  display: flex;
  flex-wrap: wrap;
  font-size: 28px;
  font-weight: bold;
  color: #ed9f3c;
  margin-bottom: 15px;
}

.userFormInputField{
  border-radius: 5px;
  outline: none;
  border: 1px solid #afafaf;
  padding: 5px 10px;
  min-width: 250px;
  font-size: 20px;
}

.userFormInputField:active{
  border: 1px solid black
}

.userFormInputField:hover{
  border: 1px solid black
}