.trackerPageGrid{
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-y: hidden;
}

.trackerPageBody{
  display: flex;
  justify-content: center;
  width: 90vw;
  flex-direction: column;
  overflow-y: hidden;
}

.trackerSearchBox{
  font-size: 28px;
  padding: 0px 10px;
  height: 60px;
  width: calc(90vw - 135px);
  border-radius: 10px;
  border: 1px solid black;
  outline: none;
  font-weight: bold;
}

.trackerBackspaceButton{
  width: 125px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: #ed9f3c;
  color: white;
  cursor: pointer;
}

.trackerBackspaceButton:hover{
  background-color: #d18d35;
}

.trackerSelectionOption{
  background-color: white;
  box-shadow: -1px 2px 4px 0px #bbbbbb;
  color: black;
  border-radius: 5px;
  padding: 5px 15px;
  margin-top: 1px;
  border: 1px solid white;
}

.trackerSelectionOption:hover{
  cursor: pointer;
  border: 1px solid #ed9f3c;
}

.addNewUserSelectionOption{
  background-color: #ed9f3c;
  box-shadow: -1px 2px 4px 0px #bbbbbb;
  color: white;
  border-radius: 5px;
  padding: 10px 15px;
  margin-top: 1px;
  border: 1px solid #ed9f3c;
  outline: none;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
}

.addNewUserSelectionOption:hover{
  background-color: #d18d35;
  border: 1px solid #d18d35;
}